import { inject }                       from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { FormSchema }                   from 'modules/bituminous-mixtures/mixtures/formulation-studies/form-schema';
import { FormulationStudiesRepository } from 'modules/bituminous-mixtures/mixtures/formulation-studies/services/repository';
import { AppContainer }                 from 'resources/services/app-container';

@inject(AppContainer, FormulationStudiesRepository, FormSchema)
export class ViewFormulationStudy extends BaseFormViewModel {
    headerTitle = 'form.title.view-record';
    formId      = 'bituminous-mixtures.mixtures.formulation-studies.view-form';

    alert          = {};
    model          = {};
    schema         = {};
    propertiesInfo = {};

    readonly = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/formulation-studies/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('bituminous-mixtures.mixtures.formulation-studies.index') && super.can([
            'bituminous-mixtures.mixtures.formulation-studies.manage',
            'bituminous-mixtures.mixtures.formulation-studies.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        if (this.model.is_validated) {
            this.info = this.infoAlertMessage('form.message.viewing-validated-record');
        }

        this.properties = this.model.properties;

        this.schema                      = this.formSchema.schema(this, true);
        this.additionalInformationSchema = this.formSchema.additionalInformationSchema(this, true);
        this.lotInfoSchema               = this.formSchema.lotInfoSchema(this, true);
        this.globalSchema                = this.formSchema.globalSchema(this, true);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
