import { bindable, bindingMode, inject } from 'aurelia-framework';
import { I18N }                          from 'aurelia-i18n';
import { AppContainer }                  from 'resources/services/app-container';

@inject(AppContainer, I18N)
export class PropertiesListing {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) model;
    @bindable propertiesRecords;
    @bindable disabled = false;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Handles attached event
     */
    attached() {
        this.initializeProperties();
    }

    /**
     * Handles properties records changed event
     */
    propertiesRecordsChanged() {
        this.initializeProperties();
    }

    /**
     * Initializes properties
     */
    initializeProperties() {
        if (Array.isArray(this.propertiesRecords)) {
            let newModel = [];

            for (let property of this.propertiesRecords) {
                let modelProperty = this.model ? this.model.find(
                    p => p.property_id === property.property_id,
                ) : null;

                if (!modelProperty) {
                    modelProperty = {
                        property_id: property.property_id,
                        active:      property.active !== undefined ? property.active : true,
                        value:       property.value !== undefined ? property.value : '',
                    };
                } else {
                    if (modelProperty.active === undefined) {
                        modelProperty.active = true;
                    }
                    if (modelProperty.value === undefined) {
                        modelProperty.value = '';
                    }
                }

                newModel.push(modelProperty);
            }

            this.model = newModel;
        }
    }

    /**
     * Gets the property value
     *
     * @param id
     * @return {*}
     */
    getModelProperty(id) {
        return this.model.find(p => p.property_id === id);
    }
}
