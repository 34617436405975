import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class FormulationStudiesRepository extends BaseRepository {
    baseUrl = 'bituminous-mixtures/mixtures/formulation-studies';

    /**
     * Fetches the next (possible) number
     *
     * @returns {*}
     */
    nextNumber(lotInterventionId, teamId) {
        return this.httpClient.get(`${this.baseUrl}/${lotInterventionId}/${teamId}/next-number`);
    }

    /**
     * Fetches all formulation studies by current lot intervention
     *
     * @return {*}
     */
    validatedByCurrentLotIntervention() {
        return this.httpClient.get(`${this.baseUrl}/validated-by-current-lot-intervention`);
    }

    /**
     * Search additional informations by criteria
     *
     * @param mixtureId
     * @param criteria
     * @return {*}
     */
    searchAdditionalInformations(mixtureId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${mixtureId}/additional-informations`, criteria);
    }
}
